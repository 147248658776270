.navbar-container {
	height: 120px;
}

.navbar-container .navbar-img {
	width: 150px;
}

.container-menu .nav-item {
	color: var(--color-primary) !important;
	font-weight: 900;
	font-size: 20px !important;
}

.container-menu .nav-item:hover {
	color: var(--color-secondary) !important;
}

.offcanvas-header h4 {
	font-size: 45px;
	font-weight: bold;
}

@media (max-width: 991px) {
	.offcanvas-body {
		width: 100%;
	}

	.offcanvas-header h5 {
		font-size: 25px;
		font-weight: bold;
	}
}
