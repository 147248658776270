/* SECCIÓN BANNER */
.carousel-container .carousel .carousel-inner .carousel-item img {
	max-height: 624px;
	min-height: 200px;
}

.carousel-item::before {
	background-color: rgba(0, 0, 0, 0.3);
	bottom: 0;
	content: '';
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
}

/* SECCIÓN QUIENES SOMOS */
.container-quienes-somos,
.container-beneficios,
.container-price,
.container-feature,
.container-contact,
.container-service {
	padding: 86px 0;
}

.container-quienes-somos .liner,
.container-beneficios .liner,
.container-price .liner,
.container-feature .liner,
.container-contact .liner,
.container-service .liner {
	color: var(--color-secondary);
	height: 6px;
	max-width: 97px;
	opacity: 1;
}

.container-price .liner {
	color: #fff;
}

.content-quienes-somos .content-info h2 {
	color: #333333;
}

.content-quienes-somos .content-info h2,
.content-beneficios .content-info h2,
.content-price .content-info h2,
.content-feature .content-info h2,
.content-contact .content-info h2,
.content-service .content-info h2 {
	font-size: 45px;
	font-weight: bold;
}

@media (max-width: 560px) {
	.content-quienes-somos .content-info h2,
	.content-beneficios .content-info h2,
	.content-price .content-info h2,
	.content-feature .content-info h2,
	.content-contact .content-info h2,
	.content-service .content-info h2 {
		font-size: 30px;
	}
}

.container-quienes-somos .content-quienes-somos .content-info {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.container-quienes-somos .content-quienes-somos .content-info .texto {
	text-align: justify;
	font-size: 20px;
}

.container-quienes-somos .content-quienes-somos .content-img {
	display: flex;
	align-items: center;
}

.container-quienes-somos .content-quienes-somos .content-img img {
	max-width: 482px;
	max-height: 588px;
	min-height: 420px;
}

/* SECCIÓN BENEFICIOS */
.container-beneficios,
.container-contact {
	background-color: #333333;
}

.content-beneficios .content-info h2,
.content-price .content-info h2,
.content-contact .content-info h2 {
	color: #fff;
	margin-bottom: 63px;
}

.content-info .title {
	display: flex;
	flex-direction: column;
}

.content-beneficios .content-info .body .body-item,
.content-price .content-info .body .body-item {
	background-color: #fff;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 20px;
	border-radius: 10px;
}

.content-price .content-info .body .body-item {
	justify-content: space-between;
}

.body .body-item i {
	font-size: 50px;
	/* color: var(--color-secondary); */
}

.content-beneficios .content-info .body {
	display: grid;
	grid-auto-rows: 450px;
	grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
	gap: 63px 90px;
}

.content-beneficios .content-info .body .body-item h4,
.container-price .content-info .body .body-item h4,
.content-feature .content-info .body .body-item .content h4,
.content-service .content-info .body .body-item .content h4 {
	font-weight: bold;
	font-size: 25px;
	margin: 30px 0;
	text-align: center;
}

.content-beneficios .content-info .body .body-item h4 {
	margin: 0;
}

.content-beneficios .content-info .body .body-item p {
	font-size: 20px;
	text-align: center;
	margin: 0;
}

.content-beneficios .content-info .body .body-item .icono {
	display: flex;
	align-items: center;
	height: 20%;
}

.content-beneficios .content-info .body .body-item .title2 {
	display: flex;
	align-items: center;
	height: 30%;
}

.content-beneficios .content-info .body .body-item .body2 {
	display: flex;
	align-items: center;
	height: 50%;
}

.content-beneficios .content-info .body .body-item:hover {
	background-color: var(--color-secondary);
	color: #fff;
}

/* SECCIÓN SERVICIOS */
.content-service .content-info .body .center {
	display: flex;
	align-items: center;
}

.content-service .content-info .body .left,
.content-service .content-info .body .right {
	display: grid;
	grid-template-rows: repeat(3, 1fr);
}

.content-service .content-info .body .body-item {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.content-service .content-info .body .body-item .content {
	padding: 10px 20px;
}

.content-service .content-info .body .body-item .content p {
	text-align: center;
}

.content-service .content-info .body .body-item .icono {
	color: var(--color-secondary);
}

@media (max-width: 991px) {
	.content-service .content-info .body .body-item .content {
		display: flex !important;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
	}

	.content-service .content-info .body .body-item .content p,
	.content-service .content-info .body .body-item .content h4 {
		text-align: left !important;
	}

	.content-service .content-info .body .body-item .content-2 {
		display: flex !important;
		flex-direction: column;
		justify-content: center;
		align-items: flex-end;
	}

	.content-service .content-info .body .body-item .content-2 p,
	.content-service .content-info .body .body-item .content-2 h4 {
		text-align: right !important;
	}
}

/* SECCIÓN PRECIO */
.container-price {
	background-image: url(assets/img/banner2.png);
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

.content-price .content-info .body {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
	gap: 60px 105px;
}

.container-price .content-info .body .body-item span {
	font-weight: bold;
	font-size: 65px;
}

.container-price .content-info .body .body-item ul {
	font-size: 18px;
	text-align: center;
	list-style: none;
}

.container-price .content-info .body .body-item ul li {
	margin: 15px 0;
}

/* SECCIÓN CARACTERÍSTICAS */
/* .container-feature {
	background-img
} */

.content-feature .content-info .body {
	display: grid;
	grid-auto-rows: auto;
	grid-template-columns: repeat(auto-fill, minmax(312px, 1fr));
	gap: 60px 205px;
}

.content-feature .content-info .body .body-item {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.content-feature .content-info .body .body-item .icono i {
	font-size: 124px;
	color: #707070;
}

.content-feature .content-info .body .body-item .content {
	padding: 20px 20px 20px 30px;
}

.content-feature .content-info .body .body-item .content p {
	font-size: 18px;
	text-align: justify;
}

@media (max-width: 991px) {
	.content-feature .content-info .body .body-item {
		flex-direction: column;
	}
}

.content-feature .content-info .content-info-img {
	max-width: 400px !important;
	min-width: 340px !important;
	height: 400px !important;
}

/* SECCIÓN CONTACTO	 */

.content-contact .content-info .body label {
	color: #fff;
}

/* SECCIÓN FOOTER */
.container-footer {
	background-color: var(--color-primary);
	color: #fff;
}

.content-footer .left,
.content-footer .right {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.content-footer .left .footer-img {
	width: 200px;
	height: 200px;
}

.content-footer .left .footer-text {
	text-align: center;
}

.content-footer .copy {
	text-align: center;
	padding: 10px;
}

.content-footer .copy a {
	color: var(--color-secondary);
	font-weight: bold;
	text-decoration: none;
}

.content-footer .right h5 {
	font-size: 25px;
	font-weight: bold;
}

.content-footer .right .contact-list {
	display: flex;
	flex-direction: column;
	height: 80%;
	justify-content: space-evenly;
	align-items: center;
	font-size: 20px;
}

.content-footer .right .contact-list i {
	margin-right: 20px;
	width: 15%;
}

.content-footer .right .contact-item {
	width: 100%;
	display: flex;
	margin: 20px 0;
}

/* BOTÓN FLOTANTE WHATSAPP */
.btn-wsp {
	position: fixed;
	width: 60px;
	height: 60px;
	line-height: 63px;
	bottom: 25px;
	right: 25px;
	background: #25d366;
	color: #fff;
	border-radius: 50px;
	text-align: center;
	font-size: 35px;
	box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.3);
	z-index: 100;
	transition: all 300ms ease;
}
.btn-wsp:hover {
	background: #fff;
	color: #20ba5a;
	text-decoration: none;
}
@media only screen and (min-width: 320px) and (max-width: 768px) {
	.btn-wsp {
		width: 63px;
		height: 63px;
		line-height: 66px;
	}
}

/* MAPA  */
.map {
	color: var(--color-secondary);
	font-size: 30px;
}

.googleMap {
	width: 100%;
	height: 590px;
}

@media (max-width: 600px) {
	.googleMap {
		height: 400px;
	}
}
